import React from 'react';
import './footer.css';
import { FaPhone, FaEnvelope, FaYoutube, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-section quick-links">
        <h2>Länkar</h2>
        <ul>
          <li><Link to="/produkter/balpressar">Balpressar</Link></li>
          <li><Link to="/produkter/komprimatorer">Komprimatorer</Link></li>
          <li><Link to="/about">Företag</Link></li>
          <li><Link to="/nyheter">Nyheter</Link></li>
          <li><Link to="/kontakt">Kontakt</Link></li>
        </ul>
      </div>
      <div className="footer-section footer-contact-info">
        <h2>Kontakta oss</h2>
        <p><FaPhone /> 0645 10 057</p>
        <p><FaEnvelope /> Vilhelm@cyclesolutions.se</p>
        <div className="social-media">
          <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
          <a href="https://www.linkedin.com/in/vilhelm-svahn-49573a1b0/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
        </div>
      </div>
    </div>
  );
}

export default Footer;