import React, { useState } from 'react';
import './productpictures.css';
import { FiDownload } from 'react-icons/fi';
import { MdOutlineDoubleArrow, MdOutlineKeyboardArrowLeft , MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from 'react-router-dom';
import pressure from '../../../assets/ikoner/press.svg';
import weight from '../../../assets/ikoner/weight.svg';
import fillingOpening from '../../../assets/ikoner/size.svg';
import wasteTypes from '../../../assets/ikoner/recycle.svg';
import volume from '../../../assets/ikoner/waste.svg';
import baleSize from '../../../assets/ikoner/balesize.svg';
import enhetInnehåll from '../../../assets/ikoner/m3innehåll.svg';

const ProductPictures = ({ product }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? product.thumbnails.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === product.thumbnails.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handleThumbnailClick = (index) => {
        setCurrentIndex(index);
    };

    const specifications = [
        { label: 'Presstryck', value: product.presstryck, unit: 't', icon: pressure },
        { label: 'Avfalls volym', value: product.volym, unit: '', icon: volume },
        { label: 'Fyllningsöppning', value: product.fyllningsöppning, unit: 'BxD (mm)', icon: fillingOpening },
        { label: 'Avfalls typer', value: product.avfallstyp?.join(', '), unit: '', icon: wasteTypes },
        { label: 'Balstorlek', value: product.balstorlek, unit: 'BxDxH (mm)', icon: baleSize },
        { label: 'Enhet', value: product.innehåll, unit: 'kubikmeter', icon: enhetInnehåll }
    ].filter(spec => spec.value !== undefined);

    const balvikt = (product.balvikt?.cardboard !== undefined) ||
                    (product.balvikt?.plastic_bottles !== undefined) ||
                    (product.balvikt?.textilier !== undefined) ||
                    (product.balvikt?.aluminium_cans !== undefined) || 
                    (product.balvikt?.steel_drums !== undefined) || 
                    (product.balvikt?.plastic !== undefined) ? 
                    { 
                        label: 'Balvikt', 
                        cardboard: product.balvikt?.cardboard, 
                        plastic: product.balvikt?.plastic, 
                        textilier: product.balvikt?.textilier,
                        aliminium: product.balvikt?.aluminium_cans,
                        bottles: product.balvikt?.plastic_bottles,
                        steel_drums: product.balvikt?.steel_drums,
                        icon: weight 
                    } : null;


    return (
        <div className="product-pictures-container">
            <div className="product-main-section">
                <div className="product-info-and-brochure">
                    <div className='product-cat-series'>
                        <h3>{product.typ} <MdOutlineDoubleArrow /> {product.serie}</h3>
                    </div>
                    <h1>{product.namn}</h1>
                    <p>{product.beskrivning}</p>
                    {/* Conditional rendering of the brochure link */}
                    {!product.modeller && (
                        <a href={product.brochureUrl} className="brochure-link" target="_blank" rel="noopener noreferrer">
                            <FiDownload /> Ladda ner broschyren
                        </a>
                    )}
                    <div className="contact-us-buttons">
                        <Link to="/kontakt" className="contact-us-btn">Kontakta oss</Link>
                    </div>
                </div>
                <div className="product-images">
                    <div className="thumbnails">
                        {product.thumbnails.map((thumbnail, index) => (
                            <img
                                key={index} 
                                src={thumbnail} 
                                alt={`Thumbnail ${index + 1}`}
                                className={currentIndex === index ? 'selected' : ''}
                                onClick={() => handleThumbnailClick(index)}
                            />
                        ))}
                    </div>
                    <div className={`main-image ${(product.typ === "Komprimatorer" || product.serie === "Horisontella balpressar") ? 'comp-main-image' : ''}`}>
                        <img
                            src={product.thumbnails[currentIndex]}
                            alt={product.namn}
                        />
                        <MdOutlineKeyboardArrowLeft className="thumbnails-arrow thumbnails-arrow-left" onClick={handlePrevious} />
                        <MdOutlineKeyboardArrowRight className="thumbnails-arrow thumbnails-arrow-right" onClick={handleNext} />
                    </div>
                </div>
            </div>
            <hr></hr>
            <div className="product-specs">
                {specifications.map((spec, index) => (
                    <div className="spec-item" key={index}>
                        <img src={spec.icon} alt={spec.label} />
                        <h3>{spec.label}</h3>
                        <p>{spec.unit ? `${spec.value} ${spec.unit}` : spec.value}</p>
                    </div>
                ))}
                {balvikt && (
                    <div className="spec-item">
                        <img src={balvikt.icon} alt={balvikt.label} />
                        <h3>{balvikt.label}</h3>
                        <p>
                            {balvikt.cardboard && <>Wellpapp {balvikt.cardboard} kg<br /></>}
                            {balvikt.bottles && <>Plastflaskor {balvikt.bottles} kg<br /></>}
                            {balvikt.textilier && <>Textilier {balvikt.textilier} kg<br /></>}
                            {balvikt.aliminium && <>Aluminiumburkar {balvikt.aliminium} kg<br /></>}
                            {balvikt.steel_drums && <>Trummor {balvikt.steel_drums} kg<br /></>}
                            {balvikt.plastic && <>Plastfolie {balvikt.plastic} kg</>}
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductPictures;