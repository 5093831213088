import React from 'react';
import './training-page-contents.css';
import trainingPic1 from '../../../assets/training/training_1.jpg';
import trainingPic2 from '../../../assets/training/training_2.jpg';
import elgigantenLogo from '../../../assets/training/elgiganten-logo.png';
import ccLogo from '../../../assets/logotyper/logo_original.svg';

const TrainingPageContents = () => {
    return (
        <div className='training-contents'>
            <div className='training-contents-container'>
                <h3>Digital träning för Elgiganten</h3>
                <div className="training-contents-divider-horizontal"></div>
                <header className="training-contents-header">
                    <div className='training-contents-header-logos'>
                        <img src={elgigantenLogo} alt='elgiganten logo' className="training-contents-logo" />
                        <div className="training-contents-divider"></div>
                        <img src={ccLogo} alt='cycle solutions logo'className="training-contents-logo" />
                    </div>
                    <h1>Vi önskar er en framgångsrik träning och ett problemfritt användande av er maskin</h1>
                </header>
                <div className="training-contents-image-container">
                    <img src={trainingPic1} alt='balpressar träning' className="training-contents-side-image" />
                    <img src={trainingPic2} alt='balpress träning' className="training-contents-side-image" />
                </div>
            </div>
            <section className="training-contents-main-video">
                <h2>Hur man använder pressen? Se hela instruktionsvideon (08:23)</h2>
                <div className='training-contents-main-video-container'>
                    <iframe 
                        src="https://player.vimeo.com/video/836463896?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameborder="0" 
                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
                        title="User Guide for Baler X25_Long-SE-140623">
                    </iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </section>
            <section className='training-contents-main-video white-bg'>
                <h2>Gå direkt till den del du är intresserad av</h2>
                <div className="training-contents-video-grid">
                    <div className='training-contents-grid-item'>
                        <h4>1. Installera maskinen</h4>
                        <div className='training-contents-main-video-container'>
                            <iframe 
                                src="https://player.vimeo.com/video/836480933?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                frameborder="0" 
                                allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
                                title="User Guide for Baler X25_Long-SE-140623">
                            </iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </div>
                    <div className='training-contents-grid-item'>
                        <h4>2. Montera balbanden</h4>
                        <div className='training-contents-main-video-container'>
                            <iframe 
                                src="https://player.vimeo.com/video/836480851?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                frameborder="0" 
                                allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
                                title="User Guide for Baler X25_Long-SE-140623">
                            </iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </div>
                    <div className='training-contents-grid-item'>
                        <h4>3. Fyll och komprimera ert avfall</h4>
                        <div className='training-contents-main-video-container'>
                            <iframe 
                                src="https://player.vimeo.com/video/836480787?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                frameborder="0" 
                                allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
                                title="User Guide for Baler X25_Long-SE-140623">
                            </iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </div>
                    <div className='training-contents-grid-item'>
                        <h4>4. Bind balen</h4>
                        <div className='training-contents-main-video-container'>
                            <iframe 
                                src="https://player.vimeo.com/video/836480734?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                frameborder="0" 
                                allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
                                title="User Guide for Baler X25_Long-SE-140623">
                            </iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </div>
                    <div className='training-contents-grid-item'>
                        <h4>5. Urbalning</h4>
                        <div className='training-contents-main-video-container'>
                            <iframe 
                                src="https://player.vimeo.com/video/836480660?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                frameborder="0" 
                                allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
                                title="User Guide for Baler X25_Long-SE-140623">
                            </iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </div>
                </div>
            </section>
            <section className="training-contents-help-section">
                <h2>Behöver du hjälp?</h2>
            </section>
            <section className="training-contents-help-steps">
                <h2>Prova detta först</h2>
                <h4>Om maskinen inte fungerar följ stegen:</h4>
                <ol>
                    <li><h2>1. Kontrollera strömmen</h2><br /><p>Kontrollera så att maskinen är korrekt inkopplad. Om den inte är påslagen är där ingen ström.</p></li>
                    <li><h2>2. Kontrollera nödstoppet</h2><br /><p>Om nödstoppet är intryckt kommer maskinen inte att starta. Vrid nödstoppen medurs för att återställa den.</p></li>
                    <li><h2>3. Kontrollera ifall balen är klar</h2><br /><p>Ifall balen är klar kommer maskinen inte att fungera. Ifall den orangea knappen lyser, öppna luckan, knyt balen och bala ur.</p></li>
                    <li><h2>4. Kontrollera så att det finns tillräckligt med band</h2><br /><p>Ifall det tar slut på band byt ut rullarna mot nya.</p></li>
                    <li><h2>5. Genomför en snabb omstart av maskinen</h2><br /><p>Koppla ifrån och tillbaka strömmen. Tryck in nödstoppen, vrid tillbaka nödstoppen. Öppna och stäng den övre luckan, tryck in startknappen två gånger och vänta tills den genomfört sin cykel.</p></li>
                </ol>
            </section>
        </div>
    );
};

export default TrainingPageContents;
